import { useContext, useEffect, useState } from "react";
import useToken from '../../useToken.js';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import "./PGP.css";

const CopyButton = props => {
  const { item, index } = props;
  const [copytext, setnewtext] = useState('Copy Output');
  const [isDisabled, setDisabled] = useState(false);

  const textcopied = () => {
    setnewtext("Copied");
  };

  return (
    <div>
      <Button onClick={coptextfunc}>{copytext}</Button>
      <br />
      <br />
    </div>
  )
};

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

function coptextfunc() {
  var copyText = document.getElementById("outputtext");
  copyToClip(copyText.innerHTML);
}


export function PGP() {
  let { filter } = useParams();
  let hostname = window.location.hostname;
  let backendURL = "";
  if (hostname === "localhost") {
    backendURL = "https://localhost:8080";
  } else {
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app";
  }
  const { token, setToken, userObject } = useToken();
  const [data, setData] = useState(() => []);
  const params = useParams();

  const SubmitButton_decrypt = props => {
    const { item, index } = props;
    const [copytext, setnewtext] = useState('Decrypt');
    const [isDisabled, setDisabled] = useState(false);

    return (
      <div>
        <Button onClick={handleClick_decrypt}>{copytext}</Button>
        <br />
        <br />
      </div>
    )
  };

    const SubmitButton_encrypt = props => {
    const { item, index } = props;
    const [copytext, setnewtext] = useState('Encrypt');
    const [isDisabled, setDisabled] = useState(false);

    return (
      <div>
        <Button onClick={handleClick_encrypt}>{copytext}</Button>
        <br />
        <br />
      </div>
    )
  };

  const decrypt = async (e) => {
    let userInput = document.getElementById('encrypted_message').value.trim();
    userInput = "-----BEGIN PGP MESSAGE-----\n" + userInput + "\n-----END PGP MESSAGE-----\n";
    let result = await fetch(backendURL + "/decrypt", {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        "pgp_string": userInput
      }),
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
        console.log(resultJson["decrypted_string"])
      setOutput(resultJson["decrypted_string"])
    }
  };

  const encrypt = async (e) => {
    let userInput = document.getElementById('submitted_message').value;
    let result = await fetch(backendURL + "/encrypt", {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        "pgp_string": userInput
      }),
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOutput(resultJson["encrypted_string"])
    }
  };

  const [output, setOutput] = useState('');

  function handleClick_decrypt() {
    decrypt();
  }
  function handleClick_encrypt() {
    encrypt();
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <head></head>
      <body>
    <div className="split-page">
      <div className="left-half">
        <form action="/">
          <div className="item">
              {<p>-----BEGIN PGP MESSAGE-----</p>}
              <textarea id="encrypted_message" placeholder="hQGMAyf7n7t5/mqfAQv+PRobLghx7A7awC1BFCcX2Hdr8srhOharZYfxFnd7/Luk&#10;9jFGd22lsNMJ+K8i8tDVopTmruSVImZK+dxrDjaX9KU0YSV1IBpMirZ5kzgRiB3N&#10;qALY+YeE0hBsBh7Op4zuy4+JGDCVUJSi6mdWvhG7fRu8HrT3i5Igl0dx5vpXhig8&#10;4LOcXurIXddGv2QloBVmOirBBjfBhEklXY0ZXaRRKSez2Rx2JAipu1nG9LcvyfEK&#10;S/FdM3ycqAtARPoc7KgFHLtfLBxWgH+HfZ1VZJZVqfDQRFh2zu/WE2s66uTp8VX0&#10;RPoBQsNs7cQ1Q+CN0AESAufW+3aLTUEZqBkLRinwoO3ZLZDg3z5Gjtyj+UIktEzj&#10;JoTcspHX9EziEmpna2eCuCa2f+EMBK4aT1UL4oC7OV2V2x8rhSykkCi+UdSCn2NZ&#10;YSEv8WTRiz5PDcfGy3QSzy8xkTc5f+ueLwesXslkn8ClLgOIu/EYGNLNBWX24OTO&#10;z2A/6e8ABS93VdOdkxl90mIBMsR1YpyI/lq/aftKwqX1VJtrWeuOC9VAI9eORT6H&#10;NuIVqeVne0hfvPvapwb9mzEgMfiI/c1IZg6eeGYLHl++deYs+xJBYF4fHvm698KL&#10;CgItG1+/SkUBGvEQUWxT93Be9g==&#10;=MTk9" rows="15"></textarea>
              {<p>-----END PGP MESSAGE-----</p>}
          </div>
          <div className="btn-block">
            <SubmitButton_decrypt />
          </div>
        </form>
      </div>
      <div className="right-half">
        <form action="/">
          <div className="item">
              {<p>Message to be encrypted:</p>}
              <textarea id="submitted_message" placeholder="...Your message here..." rows="15"></textarea>
              {<p>`</p>}
          </div>
          <div className="btn-block">
            <SubmitButton_encrypt />
          </div>
        </form>
      </div>
    </div>
      </body>

      {output.length !== 0 ?
        (<div className="btn-block"><CopyButton /></div>) :
        (<></>)
      }

    <out id="outputtext" style={{ whiteSpace: 'pre-line' }}>
      {output}
    </out>



    <br></br>
    <br></br>
    </div>
  );
}

export default PGP;
