import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import jwt_decode from "jwt-decode"


export default function Login({ setToken }) {
  
  // Backend URL
  let hostname = window.location.hostname;
  let backendURL = ""
  if(hostname === "localhost"){
    backendURL = "https://localhost:8080"
  }
  else{
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app"
  }

  const google = useRef(window.google)

  // Handle response from Google login
    // Handle response from Google login
    async function handleCallbackResponse(response){
      // Change H1
      document.getElementById("login_h1").innerHTML = "Logging you in...";
    
      // console.log("Google token: " + response.credential)
      // var userObject = jwt_decode(response.credential)
      // var userEmail = userObject.email;
      // console.log("userEmail=" + userEmail)
      document.getElementById("sign_in_form_google").hidden = true;
  
      // Call login on backend async
      const backendToken = await loginBackend(response.credential)
      if(!(backendToken)){
        document.getElementById("feedback_div").hidden = false;
      }
      else{
        // Check that response.credential is backendToken
        const loginData = backendToken['data']
        if (loginData == null){
          // Change h1
          document.getElementById("login_h1").innerHTML = "Server Error 403 - Access Denied";
  
        }
        else{
          const loginToken = backendToken['data']['token']
          if(response.credential == loginToken){
            // Change h1
            var userObject = jwt_decode(response.credential)
            document.getElementById("login_h1").innerHTML = "Welcome " + userObject.name;
      
            // console.log("Backend token: " + backendToken)
            setToken(loginData);
  
          }
          else{
            // Change h1
            document.getElementById("login_h1").innerHTML = "Server Error 403 - Access Denied";
          }
        }
  
      }
    }

  // Login to backend
  async function loginBackend(credentials){
    // console.log("Login.js :: loginBackend():: Logging in with " + credentials)
    var backendToken = fetch(backendURL + '/api/login', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + credentials
        }
      }).then(data => data.json())
    // console.log("Login.js :: loginBackend() :: Response is " + backendToken)
    return backendToken
  }

  // Google Login 
  useEffect(() => {
    // console.log("curretn is" + google.current)
    if (google.current === 'undefined'){
      window.location.reload()
    }
    else {
      /* global google */
      google.current.accounts.id.initialize({
        client_id: "88091540017-hvq7f5kp0ri9cf9n2pivdsuuvutf9fsk.apps.googleusercontent.com",
        callback: handleCallbackResponse
      });
      
      google.current.accounts.id.renderButton(
        document.getElementById("sign_in_button_google_inner"),
        { theme: "outline", size: "large" }
      )

      google.current.accounts.id.prompt();
    }

  }, [google]);

  return(
    <div className="login_body_bg">
      <div className="login_wrapper">

          {/* User login header */}
          <div className='login_header'>
              <p id="login_h1">Please Log in to ToolBox</p>
              
          </div>

          {/* User login main */}
          <div className='login_main'>

              <div className="sign_in_button_google_wrapper">
                <div id="sign_in_button_google_inner"></div>
              </div>              

            {/* Feedback */}
            <div id="feedback_div" className="error_medium" style={{display: "none"}}><p className="feedback_p">Login failed</p></div>

            {/* Login form */ }
            <div id="sign_in_form_google">
              <br></br>

              <p>Please contact Systems Engineering if you got any problems.</p>

              <div className="sign_in_button_google_wrapper">
                <div id="sign_in_button_google_inner"></div>
              </div>
            </div>
          </div>

          {/* User login footer */}
          <div className="login_footer">
            <p>&copy; 2022-2023 Advania</p>
          </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
