import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useParams } from 'react-router-dom'

import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import Login from './pages/Login/Login.js';
import Logout from './pages/Login/Logout.js';
import NoPage from './pages/NoPage.js';
import Alerts from './pages/Alerts/Alerts.js';
import IPLookup from './pages/IPLookup/IPLookup.js';
import DictPrint from './pages/DictPrint/DictPrint.js';
import PGP from './pages/PGP/PGP.js';
import Timesheets from './pages/Timesheets/Timesheets.js';

// import Open_Alerts from './pages/Alerts/Open_Alerts.js';
// import Uniplookupformed from './pages/Alerts/Unacked.js';
import Layout from './pages/Layout.js';

import useToken from './useToken.js';


function App() {

  const { token, setToken } = useToken();

  if(token === undefined || token === null || !token || token == "undefined") {
    return <Login setToken={setToken} />
  }
  else{
    // console.log("App() :: App() :: User is logged in because token=" + token)
    return (
      <BrowserRouter>
          <Routes>
                <Route index element={<Home />} />
              <Route path="/" element={<Layout />} >
                <Route path="about" element={<About />} />
                <Route path="*" element={<NoPage />} />
                <Route path="logout" element={<Logout />} />
                <Route path="iplookup" element={<IPLookup />} />
                <Route path="DictPrint" element={<DictPrint />} />
                <Route path="Timesheets" element={<Timesheets />} />
                <Route path="pgp" element={<PGP />} />
                <Route path="all_alerts" element={<Alerts />} >
                  <Route path=":filter" element={<Alerts />} />
                </Route>
              </Route>
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
