import { useContext, useEffect } from "react";
import useToken from '../../useToken.js';


export function Logout() {
  const { token, setToken } = useToken();

  useEffect(() => {
    console.log("Layout :: handleSignOut :: Init")
    localStorage.removeItem('tokenGoogleCDC');
    setToken(null);
    window.location.href = '../';
  }, [])


  return (
    <>
      <h1>c u l8r aligator</h1>
      <div className="main_box">
        <p>
        Customer Cloud Access lets you have a overview of employees at 
        Advania Cyber Defense Center and what access they have to our customers
        clouds.
        </p>
      </div>

      
      <h2>Authors</h2>
      <div className="main_box">
        <p>
        The application was created in 2022 by ACDC Systems Engineering.
        </p>
      </div>

    </>
  );
}
  
export default Logout;