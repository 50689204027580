import { NavLink, Outlet, Link } from "react-router-dom";
import useToken from '../useToken.js';
import { Logout } from "./Login/Logout.js";
const Navbar = ({}) => {
  return (
    
    <>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossOrigin="anonymous"/>

        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a href="/" className="navbar">Home</a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
             {window.location.pathname.includes("/all_alerts") &&
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="/all_alerts/all" className="nav-link">All Alerts</a>

                    </li>
                    <li className="nav-item">
                        <a href="/all_alerts/open" className="nav-link">Open Alerts</a>

                    </li>
                    <li className="nav-item">
                        <a href="/all_alerts/unacked" className="nav-link">UnAcked</a>
                    </li>
                    <li className="form-inline my-2 my-lg-0">
                    </li>
                </ul>}
              </div>
                <ul class="navbar-nav ml-auto">
                {! window.location.pathname.includes("/all_alerts") &&
                    <li class="nav-item">
                      <a href="/all_alerts/all" className="nav-link">Alerts</a>
                    </li>
                    
                }

                {! window.location.pathname.includes("/iplookup") &&
                    <li class="nav-item">
                      <a href="/iplookup" className="nav-link">IPLookUp</a>
                    </li>
                }

                {! window.location.pathname.includes("/DictPrint") &&
                    <li class="nav-item">
                      <a href="/DictPrint" className="nav-link">DictPrint</a>
                    </li>
                }

                    <li class="nav-item">
                      <a href="/logout" className="navbar">Log Out</a>
                    </li>
                </ul>
          </nav>
        </header>

        {/* Main */}
        <main>
          <Outlet />
        </main>

        {/* Footer */}
        <footer>
  <img src="guide.png" alt="ACDC Logo" style={{ width: '3%' }} />
        </footer>
      </>

  );
};

const Layout = () => {
  
  const { userObject } = useToken();
  
  return (
    
    <Navbar/>
    
  )
};

export default Layout;