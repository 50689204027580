import { useEffect,  useState } from "react";
import { useParams } from 'react-router-dom';
import useToken from '../useToken.js';
export function NoPage() {
  const { token, setToken, userObject } = useToken();
  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
    );
}
export default NoPage;