import { useContext, useEffect, useState } from "react";
import useToken from '../../useToken.js';
import {useParams } from 'react-router-dom'
import {Button} from 'react-bootstrap';
import {ToggleButton,ToggleButtonGroup} from 'react-bootstrap';
import "./DictPrint.css"

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Form from 'react-bootstrap/Form';



const CopyButton = props => {
    const { item, index } = props;
    const [copytext, setnewtext] = useState('Copy Output');
    const [isDisabled, setDisabled] = useState(false);
    const textcopied = () => {
    setnewtext("Copied")

  };


    return (
    <div>
      <Button onClick={  () =>
        coptextfunc()>
        textcopied()

      }
      >
        {copytext}
      </Button>
      <br />
      <br />
    </div>
    )
}


function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

function coptextfunc() {

  var copyText = document.getElementById("outputtext");
  console.log(copyText.innerText)
  copyToClip(copyText.innerHTML)

}

const formatresults = (item) => {
const ipdata = dataItemToKeyValues(item)

  return <ul>{ipdata}</ul>;

};




const dataItemToKeyValues = (item) => {
  try {
    if (typeof item === "undefined") {
      item = {"no_key": "no_val"};
    }

    if (Array.isArray(item)) {
      const listItems = item.map((value, index) => (
        <li key={index}>
          {dataItemToKeyValues(value)}
        </li>
      ));
      return <ul>{listItems}</ul>;
    }

    if (item === null) {
      return <div>null</div>;
    }

    if (typeof item === "object") {
      const entries = Object.entries(item);
      const listItems = entries.map(([key, value]) =>

        value === null ? (
          <li key={key}>
            <b>{key}</b>: "null"
          </li>
        ) : (
          <li key={key}>
            <b>{key}</b>: {typeof value === "object" ? dataItemToKeyValues(value) : value}
          </li>
        )
      );
      return <ul>{listItems}</ul>;
    }

    // Handle the case when the input is not a dictionary or an array
    return <ul><li>{item}</li></ul>;
  } catch (error) {
    return <div>Error: {error.message}</div>;
  }
};



export function DictPrint() {

    // Backend URL
    let { filter } = useParams();
    let hostname = window.location.hostname
    let backendURL = ""
    if(hostname === "localhost"){
    backendURL = "https://localhost:8080"
    }
    else{
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app"
    }
    const { token, setToken, userObject } = useToken();
    const [data, setData] = useState(() => [])
    const params = useParams();
    const [output, setOutput] = useState('');
    const [Jdata, setJData] = useState({});
    const SubmitButton = props => {
        const { item, index } = props;
        const [copytext, setnewtext] = useState('Submit');
        const [isDisabled, setDisabled] = useState(false);
        const textcopied = () => {
          setnewtext("Copied");
        };


        return (
          <div>
            <Button onClick={  () =>
              handleClick()

            }
            >
              {copytext}
            </Button>
            <br />
            <br />
          </div>
        )
    }
    const [toggleValue, setToggleValue] = useState(false);


    const ToggleSwitch = props => {
    const { item, index } = props;

    const toggleclicked = () => {
            if(toggleValue){
        setOutput(dataItemToKeyValues(Jdata["full"]))
        }
        else{
        setOutput(dataItemToKeyValues(Jdata["cleaned_dict"]))
        }

      setToggleValue(!toggleValue);

    };

    return (
      <div>
        <Button onClick={() => toggleclicked()}>{toggleValue ?
                    ("Cleaned")
                    :
                    (
                      "Full"
                    )
                  }</Button>
      </div>
    );
  };

    const ToggleS = props => {
    const { item, index } = props;
    const [output_type, setoutput_type] = useState("Full");

    const tglckli = () => {
    console.log(toggleValue)
    if(toggleValue){
    setOutput(dataItemToKeyValues(Jdata["full"]))
    setoutput_type("Full")
    }
    else{
    setOutput(dataItemToKeyValues(Jdata["cleaned_dict"]))
    setoutput_type("Cleaned")
    }

    setToggleValue(!toggleValue);
//    var isChecked = this.checked;
//    console.log(isChecked)
     };


    return (
        <ToggleButtonGroup
        name = "Toggle"
          color="primary"
          exclusive
          value={output_type}




          aria-label="Platform"
        >
          <ToggleButton value ="Full" onClick={tglckli}>Full</ToggleButton>
          <ToggleButton value = "Cleaned" onClick={tglckli} >Cleaned</ToggleButton>
        </ToggleButtonGroup>
    );
  };





    let resultJson;
    const submit_dict = async (e) => {

    let result = await fetch(backendURL + "/DictPrint", {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "data": document.getElementById('submiteddata').value
      }),
    });
    let resultJson = await result.json();

    if (result.status === 200 || result.status === 201) {
    setJData(resultJson)

      // console.log(Object.keys(resultJson).map(
    //     (key) => resultJson[key]
    //  ))

    const obj = Object.entries(resultJson)
    setOutput(dataItemToKeyValues(resultJson["full"]))





    }
    };





    function handleClick() {
    var message = document.getElementById('submiteddata').value;
    // var whoval = document.getElementById('WhoIsCheckBox').checked;
    // var vtval = document.getElementById('VTCheckBox').checked;
    // var spurval = document.getElementById('SpurCheckBox').checked ;
    // console.log(message)
    // console.log(whoval)
    // console.log(vtval)
    // console.log(spurval)
    submit_dict();

    ;
    }











  // Load data
  useEffect(() => {
  }, []);

    return (

<div>

  <head>

  </head>

  <body>

    <div className="IPsBox">
      <form action="/">
        <div className="item">
          {/* <p>Enter Dictionary</p> */}
          <textarea id = "submiteddata" placeholder = "{&#10;key_1 : val,&#10;key_2 : val,&#10;....&#10;       }" rows="10" ></textarea>
        </div>
        <div className="btn-block">
          <SubmitButton />
           <ToggleS/>

          {/* <button onClick={handleClick} id="submitbutton" type="button">Submit</button> */}
        </div>

      </form>
    </div>
  </body>

  {output.length != 0 ?
                    (  <div className="btn-block">
                    <CopyButton/>
                    </div>)
                    :
                    (
                      <></>
                    )
                  }

  <out id="outputtext">



  {output}



  </out>





  {/* <button onClick={coptextfunc}>{copytext}</button> */}
</div>


      );
  }

  export default DictPrint;
  ;



