import React, { useContext, useEffect, useState } from "react";
import useToken from '../../useToken.js';
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import copy_icon from './copy.png';
import copyall_icon from './copyall.png';
import ExpandableGrid from './ExpandableGrid';


import "./IPLookup.css"
const keysToAlwaysBeTrue = ["Who_Is"];

const CopyButton = (props) => {
  const {keyprop, data } = props;
  const [copytext, setnewtext] = useState('Copy Output');
  const [isDisabled, setDisabled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  function coptextfunc() {
    const jsonString = JSON.stringify(data, null, 2);
    copyToClip(`{"${keyprop}":${jsonString}}`);
     setIsClicked(true);
  }

  return (
    <div
      className={`copy-button ${isClicked ? 'clicked' : ''}`}
      onClick={coptextfunc}
    >
      <img
        src={copy_icon}
        alt="Copy Icon"
  style={{ cursor: 'pointer' }}

      />
      <br />
      <br />
    </div>
  )
}
const CopyAllButton = (props) => {
  const { resultJson } = props;
  const [copytext, setnewtext] = useState('Copy Output');
  const [isDisabled, setDisabled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  function coptextfunc() {
    const jsonString = JSON.stringify(resultJson, null, 2);
    copyToClip(jsonString);
    setIsClicked(true);
  }

  return (
    <div
      className={`copyall-button ${isClicked ? 'clicked' : ''}`}
      onClick={coptextfunc}
    >
      <img
        src={copyall_icon}
        alt="Copy All Icon"
        style={{ cursor: 'pointer' }}
      />
      <br />
      <br />
    </div>
  );
}

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

//function coptextfunc() {
//  var copyText = document.getElementById("outputtext");
//  copyToClip(copyText.innerHTML);
//}

const format_results = (item, checkboxStates = { topLevel: {}, subLevel: {} }) => {
  const { topLevel, subLevel } = checkboxStates;
    console.log("checkboxStates")
    console.log(checkboxStates)
  let filteredItem = {  };

if (Object.values(topLevel).some(Boolean)) {
    filteredItem = {};

    for (const key in topLevel) {
  if (item[key] && topLevel[key]) {
        filteredItem[key] = { ...item[key] };
      }
    }
  }else{filteredItem = item
  }

let subfilteredItem = {};

if (Object.keys(subLevel).some(key => !keysToAlwaysBeTrue.includes(key) && subLevel[key])) {
  // Your code here
  for (const key in filteredItem) {
    if (typeof filteredItem[key] === 'object') {
      subfilteredItem[key] = {}; // Create an empty object for subfilteredItem[key]
      const subKeys = Object.keys(filteredItem[key]);
      for (const subKey of subKeys) {
        if (filteredItem[key][subKey] && subLevel[subKey]) {
          subfilteredItem[key][subKey] = { ...filteredItem[key][subKey] };
        }
      }
    }
  }
} else {
  subfilteredItem = { ...filteredItem }; // Copy filteredItem if no subLevel checkboxes are checked
}


  const ipdata = dataItemToKeyValues(subfilteredItem);

    const acceptedkeys = Object.keys(subfilteredItem);
  console.log(acceptedkeys);
      for (const key in subfilteredItem) {

    const acceptedsybkeys = Object.keys(subfilteredItem[key]);
      console.log(acceptedsybkeys);

    }
  console.log("SET OUTPUT");

  return <ul>{ipdata}</ul>;
};



const listIPs = (item) => {
  const entries = Object.entries(item);
  const IPlist = entries.map(([key]) => (
    <li key={key}>
      <b>{key}</b>
    </li>
  ));
  return <ul>{IPlist}</ul>;
};


function findRefValue(obj) {
  for (const key in obj) {
    if (key === 'REF') {
      return obj[key];
    }
    if (typeof obj[key] === 'object') {
      const result = findRefValue(obj[key]);
      if (result) {
        return { key, value: result }; // Return the key along with the value
      }
    }
  }
  return null;
}

const dataItemToKeyValues = (item) => {
  const entries = Object.entries(item);
  const listItems = entries.map(([key, value]) => {
    const refLinks = [];

    return (
      <div key={key} className="item-container">
        <div className="key">{key}
        <div className="copy-button">
            <CopyButton keyprop={key} data={value} />
          </div>
          </div>
        <div className="grid">
          {typeof value === "object" &&
            <div className="sub-keys">
              {<ExpandableGrid data={value} />}
            </div>
          }
        </div>

      </div>
    );
  });

  return <div className="list-items">{listItems}</div>;
};




export function IPLU() {
  let { filter } = useParams();
  let hostname = window.location.hostname
  let backendURL = ""

  if (hostname === "localhost") {
    backendURL = "https://localhost:8080"
  } else {
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app"
  }

  const { token, setToken, userObject } = useToken();
  const [data, setData] = useState(() => [])
  const params = useParams();
  const [resultJson, setResultJson] = useState(null);

  const SubmitButton = (props) => {
    const { item, index } = props;
    const [copytext, setnewtext] = useState('Submit');
    const [isDisabled, setDisabled] = useState(false);

    const textcopied = () => {
      setnewtext("Copied");
    };

    return (
      <div>
        <Button onClick={() => handleClick()}>{copytext}</Button>
        <br />
        <br />
      </div>
    )
  }

  const lookup = async (e) => {
    let result = await fetch(backendURL + "/iplookupform", {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "data": document.getElementById('submited_data').value
      }),
    });
    let resultJson = await result.json();

    if (result.status === 200 || result.status === 201) {
      const obj = Object.entries(resultJson)
      setResultJson(resultJson);
      setOutput(format_results(resultJson))
    }
  };

  const [output, setOutput] = useState('');

  function handleClick() {

    lookup();
  }

 const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    // Load data
  }, []);

const [checkboxStates, setCheckboxStates] = useState({ topLevel: {}, subLevel: {} });
const handleCheckboxChange = (key, isTopLevel) => {
  setCheckboxStates((prevState) => {
    const newState = {
      topLevel: { ...prevState.topLevel },
      subLevel: { ...prevState.subLevel }
    };

    if (isTopLevel) {
      newState.topLevel[key] = !newState.topLevel[key];
    } else {
      newState.subLevel[key] = !newState.subLevel[key];
        keysToAlwaysBeTrue.forEach((key) => {
        newState.subLevel[key] = true;
        });
    }

    return newState;
  });
};



  useEffect(() => {
    if (resultJson) {
    setOutput(format_results(resultJson, checkboxStates));

    }
  }, [resultJson, checkboxStates]);

  let topLevelCheckboxes = null;
  let subLevelCheckboxes = null;



  if (resultJson) {
    const topLevelKeys = Object.keys(resultJson);

    topLevelCheckboxes = topLevelKeys.map((key) => (
      <div key={key}>
        <input
          type="checkbox"
          value={key}
          id={`${key}CheckBox`}
          name={key}
//          checked={selectedKey === key}
    onChange={() => handleCheckboxChange(key, true)}
        />
        <label htmlFor={`${key}CheckBox`} className="checkbox">
            <span>{key.length > 20 ? key.substring(0, 20) + "..." : key}</span>
        </label>
      </div>
    ));

const subLevelKeys = [...new Set(topLevelKeys.flatMap(key => Object.keys(resultJson[key])))];

      subLevelCheckboxes = subLevelKeys.map((subKey) => (
        <div key={subKey}>
        <input
        type="checkbox"
        value={subKey}
        id={`${subKey}CheckBox`}
        name={subKey}
        onChange={() => {
            if (!keysToAlwaysBeTrue.includes(subKey)) {
                    handleCheckboxChange(subKey, false);
                }
        }}
        checked={keysToAlwaysBeTrue.includes(subKey) || checkboxStates[subKey]}
        disabled={keysToAlwaysBeTrue.includes(subKey)}

          />
          <label htmlFor={`${subKey}CheckBox`} className="checkbox">
            <span>{subKey}</span>
          </label>
        </div>
      ));

  }

  return (
    <div className="main-container">
      <div className="input-container">
        <div className="IPsBox">
          <form action="/">
            <div className="item">
              <p>Input IPs/URLS/Hashes:</p>
              <textarea id="submited_data" placeholder="X.X.X.X &#10;X.X.X[.]X &#10;https://randomurl.org &#10;hxxp://otherandomurl.org &#10;anotherrandomurl.org &#10;dab2ad1e12aebebceef118504165130e0585faae88d56cfc06b3905bdb18d021" rows="10"></textarea>
            </div>
            <div className="btn-block">
              <SubmitButton />
            </div>
            <div className="question">
              <p>Select Outputs:</p>
              <div className="sub-level-container">
                <div className="top-level-column">
                  {topLevelCheckboxes}
                </div>

                <div className="sub-level-column">
                  {subLevelCheckboxes}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>



      <div className="output-container">
        <output id="outputtext">
          {output}
        </output>
        {output.length !== 0 ?
          <div className="btn-block">
            <CopyAllButton resultJson={resultJson} />
          </div>
          : null
        }
      </div>
    </div>
  );
}

export default IPLU;