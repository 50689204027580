import { useState } from "react";
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import './Timesheets.css'; // Import the CSS file

const Timesheets = () => {
  const { filter } = useParams();
  const hostname = window.location.hostname;
  let backendURL = "";
  if (hostname === "localhost") {
    backendURL = "https://localhost:8080";
  } else {
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app";
  }
  const { token, setToken, userObject } = useToken();
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");

  const [tableData, setTableData] = useState(null);

  const handleButtonClick = async () => {
    const startTimeInput = document.getElementById('startTimeInput');
    const endTimeInput = document.getElementById('endTimeInput');

    const startTimeValue = startTimeInput.value;
    const endTimeValue = endTimeInput.value;

    const startTimeUnix = startTimeValue ? new Date(startTimeValue).getTime() / 1000 : null;
    const endTimeUnix = endTimeValue ? new Date(endTimeValue).getTime() / 1000 : null;

    if (startTimeUnix !== null && endTimeUnix !== null) {
      console.log('Start Time (Unix Timestamp):', startTimeUnix);
      console.log('End Time (Unix Timestamp):', endTimeUnix);
      console.log('Start Time:', startTimeValue);
      console.log('End Time:', endTimeValue);
    } else {
      console.log('Please provide both start and end times.');
    }

    try {
      const response = await fetch(`${backendURL}/timesheets`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "startTimeUnix": startTimeUnix,
          "endTimeUnix": endTimeUnix,
          "startTime": startTimeValue,
          "endTime": endTimeValue
        }),
      });
      const result = await response.json();
      setTableData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderTable = () => {
    if (!tableData) {
      return null;
    }

    const columns = Object.keys(tableData);
    const rows = Object.keys(tableData[columns[0]]);

    return (
      <table className="center-table full-width">
        <thead>
          <tr>
            <th>Salary <br /> Code</th>
            {columns.map((column) => (
              <th key={column}>
                {column.split(' ')[0]} <br /> {column.split(' ').slice(1).join(' ')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row}>
              <td><strong>{row}</strong></td>
              {columns.map((column) => (
                <td key={column}>{tableData[column][row]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (


    <div className="container">
    <div className="disclaimer">
      <p className="disclaimer-text">
        Disclaimer: Please be advised this tool serves as a guide only. Users bear the responsibility for submitting accurate timesheets. Kindly review your hours and verify their alignment with the provided <a href="https://advcyber.atlassian.net/wiki/spaces/GENERAL/pages/27951107/AOU001+Timef+ring" target="_blank" rel="noopener noreferrer">documentation</a>. It utilizes OpsGenie alert timestamps to offer guidance on hours and operates within a 60-minute activity window. Any additional hours must be manually inputted. Please note that it does not account for rates on bank holidays and days preceding bank holidays.
      </p>
    </div>
      <div className="InputBox">
        <form action="/">
          <div className="starttime">
            <p>Start Date and Time:</p>
            <input
              type="datetime-local"
              id="startTimeInput"
              value={startTimeValue ? startTimeValue : "2023-01-01T17:00"}
              onChange={(e) => setStartTimeValue(e.target.value)}
              style={{ width: "15%" }} // Set a specific width in pixels


            />
          </div>
          <br /><br />
          <div className="endtime">
            <p>End Date and Time:</p>
            <input
              type="datetime-local"
              id="endTimeInput"
              value={endTimeValue ? endTimeValue : "2023-01-01T08:00"}
              onChange={(e) => setEndTimeValue(e.target.value)}
                            style={{ width: "15%" }} // Set a specific width in pixels

            />
          </div>
          <div className="btn-block">
            <Button
              disabled={!startTimeValue || !endTimeValue}
              onClick={handleButtonClick}
            >
              {(!startTimeValue || !endTimeValue) ? "Invalid dates" : "Submit"}
            </Button>
          </div>
        </form>
      </div>
      <div>
        {renderTable()}
      </div>
    </div>
  );
};

export default Timesheets;
