import { useEffect,  useState } from "react";
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
export function About() {
  const { token, setToken, userObject } = useToken();
  return (
    <div>
      <h1>About</h1>
    </div>
    );
}
export default About;