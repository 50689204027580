import { useEffect,  useState } from "react";
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import alerts_logo from './alerts.png'
import chefhat from './chefhat.png'
import shodan from './shodan.png'
import dictpic from './dict.png'
import pwned from './pwned.png'
import ip_logo from './IP.png'
import pgppic from './pgp.png'
import timesheets from './timesheets.png'
import "../../App.css"

export function Home() {
  const { token, setToken, userObject } = useToken();
  return (

 <div>
  
  <h2 className="an">ToolBox</h2>

  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossOrigin="anonymous"/>
  <link href="Home.css" rel="stylesheet"/>
  <section className="App-header">

    <div className="row">
      <div id="icons">
        <a href="/all_alerts">
            <img src={alerts_logo} width="150px" height="150px"/>
            <div class="caption">Alerts</div>
        </a>
      </div>

      <div id="icons">
        <a href="/iplookup">
            <img src={ip_logo} width="150px" height="150px"/>
            <div class="caption">LookUps</div>
        </a>
      </div>

        <div id="icons">
        <a href="/DictPrint">
            <img src={dictpic} width="150px" height="150px"/>
            <div class="caption">DictPrint</div>
        </a>
      </div>


        <div id="icons">
        <a href="/pgp">
            <img src={pgppic} width="150px" height="150px"/>
            <div class="caption">PGP</div>
        </a>
      </div>

      <div id="icons">
        <a href="/Timesheets">
            <img src={timesheets} width="150px" height="150px"/>
            <div class="caption">Timesheets (beta)</div>
        </a>
      </div>

      </div>
      <div className="row">


      <div id="icons">
        <a href="https://gchq.github.io/CyberChef/" target="_blank">
            <img src={chefhat} width="75px" height="75px"/>
            <div class="caption">CyberChef</div>
        </a>
      </div>
      <div id="icons">
        <a href="https://haveibeenpwned.com/" target="_blank">
            <img src={pwned} width="75px" height="75px"/>
            <div class="caption">HaveIBeenPwned</div>
        </a>
      </div>
      <div id="icons">
        <a href="https://www.shodan.io/"  target="_blank">
            <img src={shodan} width="75px" height="75px"/>
            <div class="caption">Shodan</div>
        </a>
      </div>
    </div>
  </section>

</div>

      
   
    );
}
export default Home;