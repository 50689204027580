import React, { useState } from 'react';
import link_icon from './linkicon.png';

const ExpandableGrid = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const baseRadius = 60; // Initial radius value
  const expandedRadius = 1500; // Adjust the expanded radius value according to your preference
  const fixedWidth = '150px'; // Set the fixed width when not expanded

  const handleClick = (event) => {
    if (event.target.tagName.toLowerCase() === 'img') {
      return;
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  const getRadius = () => {
    return isExpanded ? expandedRadius : baseRadius;
  };

  const getWidth = () => {
    return isExpanded ? 'auto' : fixedWidth;
  };

  const getRadialGradient = (key) => {
    const radius = getRadius();

    switch (key) {
      case 'Who_Is':
        return `radial-gradient(circle ${radius}px, #ffffff, #ffcccc)`;
      case 'VirusTotal':
        return `radial-gradient(circle ${radius}px, #ffffff, #0b4dda90)`;
      case 'AlienVault':
        return `radial-gradient(circle ${radius}px, #ffffff, #62af4590)`;
      case 'PassiveDNS':
        return `radial-gradient(circle ${radius}px, #ffffff, #ff895699)`;
      case 'SPUR':
        return `radial-gradient(circle ${radius}px, #ffffff, #c0c0c0)`;
      default:
        return `radial-gradient(circle ${radius}px, #ffffff00, #ffffff00)`;
    }
  };

  const firstKey = Object.keys(data)[0];
  const subKey = Object.keys(data[firstKey])[0];

const renderRefLink = (key, value) => {
  if (value && typeof value === 'object' && 'REF' in value) {
    return (
      <span>
        <span>{key}</span>
        <a href={value.REF} target="_blank" rel="noopener noreferrer">
          <img
            src={link_icon}
            alt="link icon"
            style={{
              width: '10px',
              height: '10px',
              marginRight: '2px',
              marginLeft: '5px',
              marginBottom: '10px',
            }}
          />
        </a>
      </span>
    );
  }
  return <span>{key}</span>;
};

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        background: getRadialGradient(subKey),
        width: getWidth(),
      }}
    >
      <div style={{ flex: 1, textAlign: 'center' }}>
        <b>{renderRefLink(subKey, data[firstKey])}:</b>{' '}
        {JSON.stringify(data[firstKey][subKey], null, 2)}
      </div>

      <div style={{ display: 'flex' }}>
        {Object.keys(data).map((key, index) => {
          if (index !== 0) {
            return (
              <div
                key={key}
                style={{
                  margin: '3px',
                  border: '3px solid black',
                  padding: '10px',
                  textAlign: 'center',
                  borderRadius: '30px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  whiteSpace: isExpanded ? 'normal' : 'nowrap',
                  background: getRadialGradient(key),
                  width: getWidth(),
                }}
              >
                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                  <b>{renderRefLink(key, data[key])}</b>
                </div>

                {isExpanded && (
  <div style={{ textAlign: 'left' }}>
    {Object.entries(data[key]).map(([innerKey, innerValue]) => {
      // Exclude 'REF' from the displayed content
      if (innerKey !== 'REF') {
        return (
          <div key={innerKey} style={{ margin: '10px 0', paddingLeft: '20px' }}>
            <b>{renderRefLink(innerKey, innerValue)}:</b>
            {Array.isArray(innerValue) ? (
              <ul>
                {innerValue.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : typeof innerValue === 'object' ? (
              <ul>
                {Object.entries(innerValue).map(([nestedKey, nestedValue]) => (
                  <li key={nestedKey}>
                    <b>{renderRefLink(nestedKey, nestedValue)}:</b> {nestedValue}
                  </li>
                ))}
              </ul>
            ) : (
              innerValue
            )}
          </div>
        );
      }
      return null; // Exclude 'REF'
    })}
  </div>
)}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ExpandableGrid;
